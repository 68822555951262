import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "./AuthContext";
import "./Login.css";
import { motion } from "framer-motion";
import { Navigate } from "react-router-dom"; // Add this import statement
import { AwesomeButton  } from "react-awesome-button";

function Login() {
  const navigate = useNavigate();
  const { loggedIn, handleLogin } = useContext(AuthContext);
  const adminUsername = process.env.REACT_APP_ADMIN_USERNAME;
  const adminPassword = process.env.REACT_APP_ADMIN_PASSWORD;

  useEffect(() => {
    if (loggedIn) {
      const storedUsername = localStorage.getItem("username");
      const storedPassword = localStorage.getItem("password");

      if (storedUsername && storedPassword) {
        navigate("/admin");
      }
    }
  }, [loggedIn, navigate]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const username = event.target.username.value;
    const password = event.target.password.value;

    if (username === adminUsername && password === adminPassword) {
      handleLogin();
      // localStorage.setItem("username", username);
      // localStorage.setItem("password", password);
      navigate("/admin");
      toast.success("Login successful!", { autoClose: 3000 });
    } else {
      event.target.username.value = ""; 
      event.target.password.value = ""; 
      toast.error("Invalid credentials" , { autoClose: 3000 });
    }
  };

  if (loggedIn) {
    return <Navigate to="/admin" replace />;
  }

  return (
    <motion.div
      className="login-bg"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-center justify-center login-form">
        <div className="w-full max-w-2xl bg-white shadow-xl  shadow-red-900 rounded px-8 pt-6 pb-8">
          <h2 className="text-2xl font-bold mb-8 text-center">Login</h2>
          <form onSubmit={handleFormSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                Username
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="text"
                placeholder="Enter your username"
                name="username"
                defaultValue={localStorage.getItem("username")}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Password
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                placeholder="Enter your password"
                name="password"
                defaultValue={localStorage.getItem("password")}
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                className= "font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline submit-btn"
                type="submit"
              > 
              <AwesomeButton className="submit-btn">
                Sign In
                </AwesomeButton>
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </motion.div>
  );
}

export default Login;
