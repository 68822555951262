import React from "react";
import Carousel from "./Carousel";
import "./Home.css";
import { motion } from "framer-motion";
import Flag from "react-world-flags";
import intercambio from "../Rates/images/intercambio.png";
import intermex from "../Rates/images/intermex.png";
import maxi from "../Rates/images/maxi.png";
import ria from "../Rates/images/ria.png";
import sigue from "../Rates/images/Sigue3.jpg";
import Directions from "../Directions/Directions";
// import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import ScrollToTop from 'react-scroll-up'
import {ChevronUpIcon}  from "@heroicons/react/solid";
import { useTranslation } from 'react-i18next';


function Home({ navigateToRates }) {
  const { t } = useTranslation();

  // const handleCheckoutRates = () => {
  //   navigateToRates();
  // };


  return (
    <motion.div
      className="home-bg min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8"
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-4xl mx-auto">
        <div className="header">
          <h1 className="text-4xl font-bold mb-4 home-header text-center envios-header text-gray-900">
            {t('HomePage.Header')}
          </h1>
          <div className="my-8 bg-transparent shadow-lg shadow-slate-700 overflow-hidden sm:rounded-lg mb-4">
            {/* <div className="px-4 py-5 sm:px-6">
             
            </div> */}
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <p className="text-lg text-gray-700">
                {t('HomePage.Text1')}
              </p>
              <br />
              <p className="text-lg text-gray-700 mt-2">
                {t('HomePage.Text2')}
              </p>
              <br />
              <p className="text-lg text-gray-700 mt-2">
                {t('HomePage.Text3')}
              </p>
            </div>
          </div>
        </div>

        <div>
          <h1 className="services" >{t('HomePage.Services')}</h1>
        </div>

        <div className="my-8">
          <div className="flex flex-wrap mx-auto">
            {/* Start of the supported companies card */}
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2  mb-8">
              <div className="rounded-lg shadow-lg p-8 shadow-slate-700 border-4 p border-transparent bg-transparent home-companies">
                <h3 className="text-xl font-bold mb-4 text-center">
                  {t('HomePage.MoneyTransferVia')}
                </h3>
                <div className="grid grid-cols-2 gap-3 home-companies">
                  <img src={intercambio} alt="intercambio" className="home-logos m-auto" />
                  <img src={sigue} alt="sigue" className="home-logos m-auto" />
                  <img src={intermex} alt="intermex" className="home-logos m-auto" />
                  <img src={ria} alt="ria" className="home-logos m-auto" />
                  <img src={maxi} alt="maxi" className="home-logos maxi m-auto"  />
                </div>
              </div>
            </div>
            {/* End of the supported companies card */}

            {/* Start of the Money transfers div */}
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 pl-3  mb-8 ">
              <div className="rounded-lg shadow-lg p-8 shadow-slate-700 border-4 px-4 border-transparent bg-transparent home-companies  pb-33">
                <h3 className="text-xl font-bold mb-4 text-center">
                  {t('HomePage.MoneyTransfersTo')}
                </h3>

                <div className="grid grid-cols-2 gap-4">
                  <div className="home-flags m-auto">
                    <Flag code="MX" height="16" className="home-flag" />
                  </div>
                  <div className="home-flags m-auto">
                    <Flag code="HN" height="30" className="home-flag" />
                  </div>
                  <div className="home-flags m-auto">
                    <Flag code="CO" height="16" className="home-flag" />
                  </div>
                  <div className="home-flags m-auto">
                    <Flag code="GT" height="16" className="home-flag" />
                  </div>
                </div>
              </div>
            </div>
            {/* End of the money transfers card */}

            {/* <div className="home-rates-btn">
              <AwesomeButton
                className="rates-btn"
                type="primary"
                size="xlarge"
                onPress={handleCheckoutRates}
              >
                {t('HomePage.CheckoutRates')}
              </AwesomeButton>
            </div> */}

            {/* Start of the check cashing card */}
            <div className="w-full sm:w-1/2 md:w-full lg:w-full  xl:w-full  mb-8 justify-items-center">
              <div className="rounded-lg shadow-lg p-6 shadow-slate-700 border-4 border-transparent bg-transparent home-companies">
                <h3 className="text-xl font-bold mb-4 text-center">
                  {t('HomePage.CheckCashing')}
                </h3>
                <p className="text-gray-700">
                  {t('HomePage.CheckCashingService')}
                </p>
              </div>
            </div>
            {/* End of the check cashing card */}
          </div>


      <div className="home-carousel">
        <Carousel />
      </div>
          <h1 className="directions">{t('HomePage.Directions')}</h1>
          <div className="my-8">
            <Directions />
          </div>
        </div>
      </div>

      <ScrollToTop showUnder={160}>
        <ChevronUpIcon className="w-12 h-12 text-white scroll-up" />
      </ScrollToTop>

    </motion.div>
  );
}

export default Home;
