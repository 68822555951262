import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import './Hours.css';

const daysOfWeekHours = [
  { day: 'Monday', hours: '9 AM - 7 PM' },
  { day: 'Tuesday', hours: '9 AM - 7 PM' },
  { day: 'Wednesday', hours: '9 AM - 7 PM' },
  { day: 'Thursday', hours: '9 AM - 7 PM' },
  { day: 'Friday', hours: '9 AM - 7 PM' },
  { day: 'Saturday', hours: '9 AM - 7 PM' },
  { day: 'Sunday', hours: '9:30 AM - 2 PM' }
];

function HoursOfOperation() {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center items-center hours-bg">
      <table className="w-1/2 p-5 rounded-lg table-fixed bg-transparent tracking-wide shadow-lg shadow-slate-900">
        <thead className="text-2xl font-bold mb-2 text-black">
          <tr>
            <th className="w-1/2 px-4 py-2 text-center">{t('HoursPage.Day of the Week')}</th>
            <th className="w-1/2 px-4 py-2 text-center">{t('HoursPage.Hours')}</th>
          </tr>
        </thead>
        <tbody className="text-black">
          {daysOfWeekHours.map((item, index) => (
            <motion.tr
              key={index}
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <td className="border px-4 py-2 text-center">{item.day}</td>
              <td className="border px-4 py-2 text-center">{item.hours}</td>
            </motion.tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default HoursOfOperation;
