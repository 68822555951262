import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDVGzpht2ugRubtKGP5jJrWgS9s3-MuUQw",
    authDomain: "envios-panchita-project.firebaseapp.com",
    databaseURL: "https://envios-panchita-project-default-rtdb.firebaseio.com",
    projectId: "envios-panchita-project",
    storageBucket: "envios-panchita-project.appspot.com",
    messagingSenderId: "282264397880",
    appId: "1:282264397880:web:67f8c7e49a69de12845ecd",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
