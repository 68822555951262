import React from 'react';

function Footer() {
  return (
    <footer className="bg-slate-800 py-4 text-center">
      <p className="text-white">
        &copy; {new Date().getFullYear()} Envios Pachita &#8482; All rights reserved.
      </p>
    </footer>
  );
}

export default Footer;
