import React from 'react'
import './Directions.css'

function Directions() {
  return (
    <div>
        <div className="map"> 

<iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3070.436490132571!2d-104.81134972328722!3d39.684888871566756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c628dd278f261%3A0xccdf206fe2fda3bc!2sEnvios%20Panchita%20Inc!5e0!3m2!1sen!2sus!4v1684637214821!5m2!1sen!2sus"
  width="400"
  height="450"
  style={{ border: "0" }} 
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
  title="maps"
  className="justify-items-center mx-auto container"
></iframe> 
</div>
    </div>
  )
}

export default Directions;