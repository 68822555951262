

import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Carousel.css'
import inside from './images/inside.JPG'
import location from './images/location.png'
import opensign from './images/opensign.JPG'
import outside from './images/outside.JPG'

const ImageCarousel = () => {
    return (
        <div className="w-full flex justify-center">
            <Carousel
                showArrows={true}
                autoPlay={true}
                infiniteLoop={true}
                showStatus={false}
                showThumbs={false}
                interval={5000}
                className="w-full max-w-screen-lg carousel"
            >
                <div>
                    <img src={outside} alt="1" />
                </div>
                <div>
                    <img src={inside} alt="2" />
                </div>
                <div>
                    <img src={opensign} alt="3" />
                </div>
                <div>
                    <img src={location} alt="4" />
                </div>
            </Carousel>
        </div>
    );
}

export default ImageCarousel;