import React from 'react';
import './i18n'; // This is the i18n initialization file
import ReactDOM from 'react-dom/client'
import 'tailwindcss/tailwind.css'; 
import './index.css';
// import ReactGA from "react-ga";


import App from './App';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
<React.StrictMode>
      <App />
  </React.StrictMode>
);