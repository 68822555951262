import React, { useState, useEffect } from "react";
import "./Rates.css";
import Flag from "react-world-flags";
import intercambio from "./images/intercambio.png";
import intermex from "./images/intermex.png";
import maxi from "./images/maxi.png";
import ria from "./images/ria.png";
import sigue from "./images/Sigue3.jpg";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import ScrollToTop from 'react-scroll-up'
import {ChevronUpIcon}  from "@heroicons/react/solid";
import { db } from "../../components/services/firebase";
import { getDocs, collection, doc, getDoc } from 'firebase/firestore';



function getCompaniesFromStorage() {
  return (
    JSON.parse(localStorage.getItem("companies")) || [
      {
        name: "Intercambio",
        logo: intercambio,
        rates: {
          Mexico: "1.23",
          Honduras: "2.45",
          Colombia: "3.67",
          Guatemala: "4.89",
        },
      },
      {
        name: "Sigue",
        logo: sigue,
        rates: {
          Mexico: "1.23",
          Honduras: "2.45",
          Colombia: "3.67",
          Guatemala: "4.89",
        },
      },
      {
        name: "Intermex",
        logo: intermex,
        rates: {
          Mexico: "1.23",
          Honduras: "2.45",
          Colombia: "3.67",
          Guatemala: "4.89",
        },
      },
      {
        name: "Ria",
        logo: ria,
        rates: {
          Mexico: "1.23",
          Honduras: "2.45",
          Colombia: "3.67",
          Guatemala: "4.89",
        },
      },
      {
        name: "Maxi",
        logo: maxi,
        rates: {
          Mexico: "1.23",
          Honduras: "2.45",
          Colombia: "3.67",
          Guatemala: "4.89",
        },
      },
    ]
  );
}

function Rates() {
  const [companies, setCompanies] = useState(getCompaniesFromStorage);
  const [updatedDateTime, setUpdatedDateTime] = useState("");
  const { t } = useTranslation();
  const location = useLocation();
  const { fromAdminDashboard } = location.state || {};

  useEffect(() => {
  const fetchData = async () => {
    const companiesCollection = collection(db, 'companies');
    const companySnapshot = await getDocs(companiesCollection);
    const companyList = companySnapshot.docs.map(doc => doc.data());
    setCompanies(companyList);

    // Fetch the last updated date and time from Firestore
    const dateRef = doc(db, 'dates', 'lastUpdated');
    const dateSnap = await getDoc(dateRef);
    const dateTime = dateSnap.exists() ? dateSnap.data().dateTime : '';
    setUpdatedDateTime(dateTime);
  };

  fetchData();
}, []);
  

  const countries = [
    { country: "Mexico", currency: "MXN", flag: "MX" },
    { country: "Honduras", currency: "HNL", flag: "HN" },
    { country: "Colombia", currency: "COP", flag: "CO" },
    { country: "Guatemala", currency: "GTQ", flag: "GT" },
  ];

  return (
    <motion.div
      className="rates-bg"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {fromAdminDashboard && (
        <div className="date">
          <p className="text-right pr-4 date-txt">{t('RatesPage.Updated as of')}: {updatedDateTime}</p>
        </div>
      )}
      {!fromAdminDashboard && (
        <div className="date">
          <p className="text-right pr-10 date-txt">{t('RatesPage.Updated as of')}: {updatedDateTime}</p>
        </div>
      )}
      <div className="rates-title">
        <h1 className="today-rates text-center mx-auto mt-10">{t('RatesPage.Today\'s Rates')}</h1>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 cards">
        {companies.map((company, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg p-4 text-center shadow-slate-900"
          >
            <div className="text-lg font-bold mt-4 flex justify-center items-center logo-header">
              <img
                src={company.logo}
                alt={`${company.name} Logo`}
                className="h-10 w-18 company-logo"
              />
            </div>
            <div className="grid grid-cols-3 gap-4 items-center font-bold text-gray-600 mb-4 rates-header">
              <div className="flex items-center justify-center">{t('RatesPage.Country')}</div>
              <div className="flex items-center justify-center currency">{t('RatesPage.Currency')}</div>
              <div className="flex items-center justify-center">{t('RatesPage.Rates')}</div>
            </div>
            {countries.map((country, countryIndex) => (
              <div
                key={countryIndex}
                className="grid grid-cols-3 gap-2 items-center"
              >
                <div className="flex items-center">
                  <Flag
                    code={country.flag}
                    height="64"
                    alt={`${country.country} Flag`}
                    className="mx-auto h-12 flags"
                  />
                  <p className="text-gray-600 ml-2">{country.country}</p>
                </div>
                <div>
                  <p className="text-gray-600 ml-8">{country.currency}</p>
                </div>
                <div>
                  <p className="text-gray-600">{company.rates[country.country]}</p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <ScrollToTop showUnder={160}>
        <ChevronUpIcon className="w-12 h-12 text-white scroll-up" />
      </ScrollToTop>
    </motion.div>
  );
}

export default Rates;
