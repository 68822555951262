import React, { useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ContactUs.css";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";

const ContactUs = () => {
  const { t } = useTranslation();

  const [state, setState] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Send email using emailjs
    emailjs
      .sendForm(
        "service_b5c2elm",
        "template_wi4k1p7",
        event.target,
        "h6sxEXgnE5YqOtlYX"
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        toast.success(t("ContactUs.SuccessMessage")); // Show success toast
      })
      .catch((error) => {
        console.log("FAILED...", error);
        toast.error(t("ContactUs.ErrorMessage")); // Show error toast
      });

    // Clear form fields
    setState({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <div className="flex justify-center items-center contact-form">
      <motion.form
        className="max-w-3xl w-full mx-auto p-4 m-4 bg-transparent rounded shadow-xl shadow-slate-900 form"
        onSubmit={handleSubmit}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-3xl font-bold text-center">{t("ContactUs.Title")}</h1>
        <div className="mt-4">
          <label className="block text-sm font-bold mb-2">{t("ContactUs.Name")}</label>
          <motion.input
            className="w-full shadow appearance-none border rounded py-2 px-3 text-grey-darker"
            type="text"
            name="name"
            value={state.name}
            onChange={handleInputChange}
            required
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          />
        </div>
        <div className="mt-4">
          <label className="block text-sm font-bold mb-2">{t("ContactUs.Email")}</label>
          <motion.input
            className="w-full shadow appearance-none border rounded py-2 px-3 text-grey-darker"
            type="email"
            name="email"
            value={state.email}
            onChange={handleInputChange}
            required
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          />
        </div>
        <div className="mt-4">
          <label className="block text-sm font-bold mb-2">{t("ContactUs.Message")}</label>
          <motion.textarea
            className="w-full shadow appearance-none border rounded py-2 px-3 text-grey-darker"
            name="message"
            value={state.message}
            onChange={handleInputChange}
            required
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          />
        </div>
        <div className="mt-4">
          <AwesomeButton className="send-btn">
            {t("ContactUs.SendMessage")}
          </AwesomeButton>
        </div>
      </motion.form>

      <ToastContainer /> {/* Add ToastContainer at the root of the component */}
     
    </div>
  );
};

export default ContactUs;
