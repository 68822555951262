import React from "react";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import Home from './pages/Home/Home'
import Rates from './pages/Rates/Rates'
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import { AuthProvider, useAuth } from "./pages/Login/AuthContext";
import Hours from "./pages/Hours/Hours";
import ContactUs from "./pages/ContactUS/ContactUs";
import Footer from "./components/Footer/Footer";
import Directions from "./pages/Directions/Directions";
import { ToastContainer } from "react-toastify";

function PrivateRoute({ element }) {
  const { loggedIn } = useAuth();

  return loggedIn ? (
    element
  ) : (
    <Navigate to="/login" replace />
  );
}

function App() {
  
  return (
    <>
      <AuthProvider>
        <Router> 
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/rates" element={<Rates />} />
            <Route path="/login" element={<Login />} />
            <Route path="/hours" element={<Hours />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/directions" element={<Directions />} />
            <Route
              path="/admin"
              element={<PrivateRoute element={<AdminDashboard />} />}
            />
          </Routes>
          <ToastContainer position="top-right" />
        </Router>
      </AuthProvider>
      <Footer />
    </>
  );
}

export default App;
