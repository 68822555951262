import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import intercambio from "./images/intercambio.png";
import intermex from "./images/intermex.png";
import maxi from "./images/maxi.png";
import ria from "./images/ria.png";
import sigue from "./images/Sigue3.jpg";
import { ToastContainer, toast } from "react-toastify";
import "./AdminDashboard.css";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { db } from "../../components/services/firebase";
import { collection, setDoc, doc } from "firebase/firestore";

function getCompaniesFromStorage() {
  return (
    JSON.parse(localStorage.getItem("companies")) || [
      {
        name: "Intercambio",
        logo: intercambio,
        rates: {
          Mexico: "1.23",
          Honduras: "2.45",
          Colombia: "3.67",
          Guatemala: "4.89",
        },
      },
      {
        name: "Sigue",
        logo: sigue,
        rates: {
          Mexico: "1.23",
          Honduras: "2.45",
          Colombia: "3.67",
          Guatemala: "4.89",
        },
      },
      {
        name: "Intermex",
        logo: intermex,
        rates: {
          Mexico: "1.23",
          Honduras: "2.45",
          Colombia: "3.67",
          Guatemala: "4.89",
        },
      },
      {
        name: "Ria",
        logo: ria,
        rates: {
          Mexico: "1.23",
          Honduras: "2.45",
          Colombia: "3.67",
          Guatemala: "4.89",
        },
      },
      {
        name: "Maxi",
        logo: maxi,
        rates: {
          Mexico: "1.23",
          Honduras: "2.45",
          Colombia: "3.67",
          Guatemala: "4.89",
        },
      },
    ]
  );
}

function AdminDashboard() {
  const logoVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  const initialCountries = [
    { country: "Mexico", currency: "MXN", flag: "MX" },
    { country: "Honduras", currency: "HNL", flag: "HN" },
    { country: "Colombia", currency: "COP", flag: "CO" },
    { country: "Guatemala", currency: "GTQ", flag: "GT" },
  ];

  const [companies, setCompanies] = useState(getCompaniesFromStorage);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [joke, setJoke] = useState("");
  const navigate = useNavigate();

  const fetchJoke = async () => {
    const jokeResponse = await fetch(
      "https://v2.jokeapi.dev/joke/Miscellaneous,Pun,Spooky,Christmas?blacklistFlags=nsfw,religious,political,racist,sexist,explicit&format=json"
    );
    const jokeData = await jokeResponse.json();

    if (jokeData.joke) {
      // Single line joke
      setJoke(jokeData.joke);
    } else {
      // Two part joke
      setJoke(jokeData.setup + " ... " + jokeData.delivery);
    }
  };

  useEffect(() => {
    localStorage.setItem("companies", JSON.stringify(companies));
  }, [companies]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    fetchJoke();
  }, []);

  const handleRateChange = async (event, companyIndex, countryName) => {
    const newRate = event.target.value;
    setCompanies((prevCompanies) => {
      const newCompanies = [...prevCompanies];
      newCompanies[companyIndex].rates[countryName] = newRate;
      return newCompanies;
    });
  
    // update company in Firestore
    const newCompanies = [...companies]; // Create a copy of the current companies state
    newCompanies[companyIndex].rates[countryName] = newRate;
    const companyRef = doc(db, 'companies', companyIndex.toString());
    await setDoc(companyRef, newCompanies[companyIndex]);
  };
  

  const handleCompanyUpdates = async () => {
    const updatedDateTime = new Date().toLocaleString();
    
    // Save the updated date and time to Firestore
    const dateRef = doc(db, 'dates', 'lastUpdated');
    await setDoc(dateRef, { dateTime: updatedDateTime });
  
    toast.success("All companies updated successfully!");
  
    // Save updated companies to Firebase
    const companiesRef = collection(db, 'companies');
    for (let i = 0; i < companies.length; i++) {
      const companyDocRef = doc(companiesRef, i.toString());
      await setDoc(companyDocRef, companies[i]);
    }
  
    navigate("/rates", { state: { fromAdminDashboard: true } });
  };

  const handleReset = () => {
    setCompanies((prevCompanies) => {
      const resetCompanies = prevCompanies.map((company) => ({
        ...company,
        rates: Object.fromEntries(
          Object.entries(company.rates).map(([country, _]) => [country, ""])
        ),
      }));
      localStorage.setItem("companies", JSON.stringify(resetCompanies));

      return resetCompanies;
    });

    // Reset data in Firebase
    const resetCompanies = companies.map((company) => ({
      ...company,
      rates: Object.fromEntries(
        Object.entries(company.rates).map(([country, _]) => [country, ""])
      ),
    }));
    const companiesRef = collection(db, 'companies');
    resetCompanies.forEach(async (company, i) => {
      const companyDocRef = doc(companiesRef, i.toString());
      await setDoc(companyDocRef, company);
    });
};


  return (
    <div className="p-8 space-y-4 bg-gray-100 rounded-lg dashboard-bg">
      <ToastContainer />
      <div className="text-right text-black text-sm time">
        Current Time: {currentDateTime.toLocaleString()}
      </div>
      <h1 className="text-4xl font-bold text-center">
        <motion.span
          className="inline-block"
          initial="hidden"
          animate="visible"
          variants={logoVariants}
          transition={{ duration: 0.5 }}
        >
          Welcome Admin
        </motion.span>
      </h1>

      <div className="flex flex-wrap justify-between">
        {companies.map((company, companyIndex) => (
          <div
            key={companyIndex}
            className="max-w-lg flex-grow bg-white p-2 rounded-lg shadow-lg m-2"
          >
            <h2 className="text-2xl font-bold text-center">{company.name}</h2>
            {initialCountries.map((country, countryIndex) => (
              <div
                key={countryIndex}
                className="flex justify-between items-center mt-2"
              >
                <div className="flex items-center">
                  <span>{country.country}</span>
                  <input
                    className="ml-2 px-2 py-1 border rounded-md"
                    type="text"
                    value={company.rates[country.country]}
                    onChange={(e) =>
                      handleRateChange(e, companyIndex, country.country)
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="mt-4">
        <button className="update-btn" onClick={handleCompanyUpdates}>
          <AwesomeButton className="update-btn">Update All Companies</AwesomeButton>
        </button>
        <button className="reset-button" onClick={handleReset}>
          <AwesomeButton className="reset-btn">Reset All Fields</AwesomeButton>
        </button>
      </div>
      <div className="text-center text-gray-700 mt-4 jokes">
        <h2 className="text-2xl font-bold">Joke of the Day</h2>
        <p className="mt-2 text-lg text-gray-600">{joke}</p>
      </div>
    </div>
  );
}

export default AdminDashboard;
