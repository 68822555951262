import React, { useState, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { AuthContext } from "../../pages/Login/AuthContext";
import { motion } from "framer-motion";
import { AwesomeButton } from "react-awesome-button";
import { useTranslation } from "react-i18next";
import "react-awesome-button/dist/styles.css";
import envios from "../../pages/Home/images/Envios.png";
import "./Navbar.css";
import Flag from "react-world-flags";

const variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // handleLogout needs to go here
  const { loggedIn, handleLogout } = useContext(AuthContext);
  const { t, i18n } = useTranslation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <motion.div
      className="navbar"
      initial="hidden"
      animate="show"
      exit="exit"
      variants={variants}
    >
      <nav className="flex items-center justify-between flex-wrap p-5 navbar-bg flex-grow">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <Link to="/" className="envios-logo">
            <img src={envios} alt="envios-logo" className="envios-logo w-48" />
          </Link>
        </div>
        <div className="lg:hidden">
          <button
            className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-white hover:border-transparent toggle"
            onClick={toggleMenu}
          >
            <h2 className="menu">Menu</h2>
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isMenuOpen ? (
                <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 4C0 3.44772 0.447715 3 1 3H19C19.5523 3 20 3.44772 20 4C20 4.55228 19.5523 5 19 5H1C0.447716 5 0 4.55228 0 4ZM0 9C0 8.44772 0.447715 8 1 8H19C19.5523 8 20 8.44772 20 9C20 9.55228 19.5523 10 19 10H1C0.447716 10 0 9.55228 0 9ZM0 16C0 15.4477 0.447715 15 1 15H19C19.5523 15 20 15.4477 20 16C20 16.5523 19.5523 17 19 17H1C0.447716 17 0 16.5523 0 16Z"
                />
                ) : (
                  <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 3C0 2.44772 0.447715 2 1 2H19C19.5523 2 20 2.44772 20 3C20 3.55228 19.5523 4 19 4H1C0.447716 4 0 3.55228 0 3ZM0 9C0 8.44772 0.447715 8 1 8H19C19.5523 8 20 8.44772 20 9C20 9.55228 19.5523 10 19 10H1C0.447716 10 0 9.55228 0 9ZM0 15C0 14.4477 0.447715 14 1 14H19C19.5523 14 20 14.4477 20 15C20 15.5523 19.5523 16 19 16H1C0.447716 16 0 15.5523 0 15Z"
                />
              )}
            </svg>
          </button>
        </div>
        <div
          className={`${
            isMenuOpen ? "block" : "hidden"
          } w-full flex-grow lg:flex lg:items-center lg:w-auto mt-4 lg:mt-0`}
        >
          <div className="text-lg lg:flex-grow links">
            <NavLink
              to="/"
              className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-emerald-400 mr-4"
              onClick={toggleMenu}
            >
              {t("Navbar.Home")}
            </NavLink>
            <NavLink
              to="/rates"
              className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-emerald-400 mr-4"
              onClick={toggleMenu}
            >
              {t("Navbar.Rates")}
            </NavLink>
            <NavLink
              to="/hours"
              className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-emerald-400 mr-4"
              onClick={toggleMenu}
            >
              {t("Navbar.Hours of Operation")}
            </NavLink>
            <NavLink
              to="/contactus"
              className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-emerald-400 mr-4"
              onClick={toggleMenu}
            >
              {t("Navbar.Contact Us")}
            </NavLink>
            {loggedIn && (
              <NavLink
                to="/admin"
                className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-emerald-400 mr-4"
                onClick={toggleMenu}
              >
                {t("Navbar.Admin Dashboard")}
              </NavLink>
            )}
          </div>
          <div>
            {loggedIn && (
              <NavLink
                to="/login"
                className="inline-block text-sm px-4 py-2 leading-none  mt-4 lg:mt-0 ml-4"
                onClick={() => {
                  toggleMenu();
                  handleLogout();
                }}
              >
                <AwesomeButton className="logout-button">
                  {t("Navbar.Logout")}
                </AwesomeButton>
              </NavLink>
            )}
            {!loggedIn && (
              <Link to="/login" onClick={toggleMenu}>
                <AwesomeButton className="admin-login-button">
                  {t("Navbar.Admin Log in")}
                </AwesomeButton>
              </Link>
            )}
          </div>
        </div>

        <div className="translations">
          <div className="english">
            <Flag code="US" height="5" className="home-flag w-7" />
            <button onClick={() => changeLanguage("en")} className="en-btn">
              ENG
            </button>
          </div>

          <div className="spanish">
            <Flag code="ES" height="5" className="home-flag w-7" />

            <button onClick={() => changeLanguage("es")} className="es-btn">
              ESP
            </button>
          </div>
        </div>
      </nav>
    </motion.div>
  );
}

export default Navbar;
